<template>
  <section class="support">
    <div class="container">
      <h2 class="page-title">Contact Us</h2>
      <p style="margin: 0 auto 64px; width: 80%">
        We are here to answer any questions you may have about our app
        experiences. Reach out to us and we'll respond as soon as we can.
      </p>
    </div>

    <Contact />
    <Footer style="position:fixed:left:0;bottom:0" />
  </section>
</template>
<script>
import Footer from "@/components/Footer/Footer";
import Contact from "@/components/Contact/index";
export default {
  components: {
    Footer,
    Contact
  }
};
</script>
<style>
.page-title {
  font-size: 32px;
  text-align: center;
  padding: 64px 0 32px;
}
.support {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.support .contact-filed {
  flex: 1;
  margin-bottom: 42px;
}
</style>
