/**
 * 处理ins与cg api的加密
 * 写在同一文件夹避免重复引入crypto-js
 */
import CryptoJS from "crypto-js/core";
import Utf8 from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";

const key = Utf8.parse("9vApxLk5G3PAsJrM");
const iv = Utf8.parse("FnJL7EDzjqWjcaY9");
const zvKey = "gps";
const encryptVersion = "I3";

// =======================  cg api的加密  ===========================

/**
 * cg api 参数加密
 * @param str
 */
const encrypt = (str) => {
  const aes = AES.encrypt(JSON.stringify(str), key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return aes.ciphertext.toString();
};
/**
 * 获取加密后的数据
 * @param str
 */
export const getEncryptData = (str) => {
  return {
    zv: zvKey,
    data: encryptVersion + encrypt(str),
  };
};
