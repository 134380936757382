<template>
  <div
    id="allrecords"
    class="t-records"
    data-hook="blocks-collection-content-node"
    data-tilda-project-id="1254790"
    data-tilda-page-id="5438307"
    data-tilda-formskey="98dd4f535c9ecb6fe7b9ecf98656f1f6"
  >
    <div
      id="rec100944058"
      class="r t-rec t-screenmax-320px"
      style=""
      data-animationappear="off"
      data-record-type="396"
      data-screen-max="320px"
    >
      <div class="t396">
        <div
          class="t396__artboard rendered"
          data-artboard-recid="100944058"
          data-artboard-height="670"
          data-artboard-height-res-960="580"
          data-artboard-height-res-640="950"
          data-artboard-height-res-480="840"
          data-artboard-height-res-320="580"
          data-artboard-height_vh=""
          data-artboard-valign="center"
          data-artboard-ovrflw=""
          data-artboard-proxy-min-offset-top="0"
          data-artboard-proxy-min-height="950"
          data-artboard-proxy-max-height="950"
        >
          <div class="t396__carrier" data-artboard-recid="100944058"></div>
          <div class="t396__filter" data-artboard-recid="100944058"></div>
          <div
            class="t396__elem tn-elem tn-elem__1009440581470209944682"
            data-elem-id="1470209944682"
            style="top: 860px; left: 102px; width: 540px"
          >
            <div class="tn-atom" field="tn_text_1470209944682">
              <strong>Magic Face Editor </strong>
            </div>
          </div>
          <div
            class="t396__elem tn-elem tn-elem__1009440581470210011265"
            data-elem-id="1470210011265"
            style="top: 912px; left: 152px; width: 440px"
          >
            <div class="tn-atom" field="tn_text_1470210011265">
              Aging Effect & Future Baby Maker
            </div>
          </div>
        </div>
      </div>

      <!-- /T396 -->
    </div>
    <div
      id="rec101354235"
      class="r t-rec t-screenmin-320px t-screenmax-480px"
      style=""
      data-animationappear="off"
      data-record-type="396"
      data-screen-min="320px"
      data-screen-max="480px"
    >
      <div class="t396">
        <div
          class="t396__artboard rendered"
          data-artboard-recid="101354235"
          data-artboard-height="670"
          data-artboard-height-res-960="580"
          data-artboard-height-res-640="950"
          data-artboard-height-res-480="840"
          data-artboard-height-res-320="700"
          data-artboard-height_vh=""
          data-artboard-valign="center"
          data-artboard-ovrflw=""
          data-artboard-proxy-min-offset-top="0"
          data-artboard-proxy-min-height="700"
          data-artboard-proxy-max-height="700"
        >
          <div class="t396__carrier" data-artboard-recid="101354235"></div>
          <div class="t396__filter" data-artboard-recid="101354235"></div>
          <div
            class="t396__elem tn-elem tn-elem__1013542351470209944682"
            data-elem-id="1470209944682"
            style="
              top: 100px;
              left: 50%;
              transform: translateX(-50%);
              width: 310px;
            "
          >
            <div class="tn-atom" field="tn_text_1470209944682">
              Magic Face Editor
            </div>
          </div>
          <div
            class="t396__elem tn-elem tn-elem__1013542351470210011265"
            data-elem-id="1470210011265"
            style="
              top: 206px;
              left: 50%;
              transform: translateX(-50%);
              width: 300px;
            "
          >
            <div class="tn-atom" field="tn_text_1470210011265">
              Aging Effect & Future Baby Maker
            </div>
          </div>
        </div>
      </div>

      <!-- /T396 -->
    </div>
    <div
      id="rec100950502"
      class="r t-rec t-screenmin-480px t-screenmax-640px"
      style=""
      data-animationappear="off"
      data-record-type="396"
      data-screen-min="480px"
      data-screen-max="640px"
    >
      <div class="t396">
        <div
          class="t396__artboard rendered"
          data-artboard-recid="100950502"
          data-artboard-height="670"
          data-artboard-height-res-960="580"
          data-artboard-height-res-640="950"
          data-artboard-height-res-480="840"
          data-artboard-height-res-320="620"
          data-artboard-height_vh=""
          data-artboard-valign="center"
          data-artboard-ovrflw=""
          data-artboard-proxy-min-offset-top="0"
          data-artboard-proxy-min-height="950"
          data-artboard-proxy-max-height="950"
        >
          <div class="t396__carrier" data-artboard-recid="100950502"></div>
          <div class="t396__filter" data-artboard-recid="100950502"></div>
          <div
            class="t396__elem tn-elem tn-elem__1009505021470209944682"
            style="top: 860px; left: 102px; width: 540px"
            data-elem-id="1470209944682"
          >
            <div class="tn-atom" field="tn_text_1470209944682">
              Magic Face Editor
            </div>
          </div>
          <div
            class="t396__elem tn-elem tn-elem__1009505021470210011265"
            data-elem-id="1470210011265"
            style="top: 912px; left: 152px; width: 440px"
          >
            <div class="tn-atom" field="tn_text_1470210011265">
              Aging Effect & Future Baby Maker
            </div>
          </div>
        </div>
      </div>

      <!-- /T396 -->
    </div>
    <div
      id="rec100950561"
      class="r t-rec t-screenmin-640px t-screenmax-980px"
      style=""
      data-animationappear="off"
      data-record-type="396"
      data-screen-min="640px"
      data-screen-max="980px"
    >
      <div class="t396">
        <div
          class="t396__artboard rendered"
          data-artboard-recid="100950561"
          data-artboard-height="670"
          data-artboard-height-res-960="580"
          data-artboard-height-res-640="950"
          data-artboard-height-res-480="840"
          data-artboard-height-res-320="620"
          data-artboard-height_vh=""
          data-artboard-valign="center"
          data-artboard-ovrflw=""
          data-artboard-proxy-min-offset-top="0"
          data-artboard-proxy-min-height="580"
          data-artboard-proxy-max-height="580"
        >
          <div class="t396__carrier" data-artboard-recid="100950561"></div>
          <div class="t396__filter" data-artboard-recid="100950561"></div>
          <div
            class="t396__elem tn-elem tn-elem__1009505611470209944682"
            data-elem-id="1470209944682"
            style="
              top: 100px;
              left: 50%;
              transform: translateX(-50%);
              width: 580px;
            "
          >
            <div class="tn-atom" field="tn_text_1470209944682">
              Magic Face Editor
            </div>
          </div>
          <div
            class="t396__elem tn-elem tn-elem__1009505611470210011265"
            data-elem-id="1470210011265"
            style="
              top: 206px;
              left: 50%;
              transform: translateX(-50%);
              width: 380px;
            "
          >
            <div class="tn-atom" field="tn_text_1470210011265">
              Aging Effect & Future Baby Maker
            </div>
          </div>
        </div>
      </div>

      <!-- /T396 -->
    </div>
    <div
      id="rec98245500"
      class="r t-rec t-rec_pt_0 t-rec_pb_0 t-screenmin-980px"
      style="padding-top: 0px; padding-bottom: 0px"
      data-animationappear="off"
      data-record-type="391"
      data-screen-min="980px"
    >
      <!-- T391 --><!-- cover -->
      <div
        class="t-cover"
        id="recorddiv98245500"
        bgimgfield="img"
        style="height: 100vh"
      >
        <div
          class="t-cover__carrier loaded"
          id="coverCarry98245500"
          data-content-cover-id="98245500"
          data-content-cover-height="100vh"
          data-content-cover-parallax=""
          style="height: 100vh; background-attachment: scroll"
          src=""
        ></div>
        <div
          class="t-cover__filter"
          style="
            height: 100vh;
            background-image: -moz-linear-gradient(
              top,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 0)
            );
            background-image: -webkit-linear-gradient(
              top,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 0)
            );
            background-image: -o-linear-gradient(
              top,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 0)
            );
            background-image: -ms-linear-gradient(
              top,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 0)
            );
            background-image: linear-gradient(
              top,
              rgba(255, 255, 255, 0),
              rgba(255, 255, 255, 0)
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#feffffff', endColorstr='#feffffff');
          "
        ></div>
        <div class="t391">
          <div class="t-container">
            <div class="t391__firstcol t-col t-col_7">
              <div
                class="t-cover__wrapper t-valign_middle"
                style="height: 100vh"
              >
                <div class="t391__textwrapper t-align_left">
                  <div
                    class="t391__title t-title t-title_xs"
                    style=""
                    field="title"
                  >
                    <div
                      style="
                        font-size: 52px;
                        line-height: 40px;
                        font-family: 'Ubuntu';
                        color: #3d4652;
                      "
                    >
                      Magic Face Editor
                    </div>
                  </div>
                  <div
                    class="t391__text t-descr t-descr_sm"
                    style=""
                    field="text"
                  >
                    <div
                      style="
                        font-size: 24px;
                        line-height: 40px;
                        font-family: 'Ubuntu';
                        color: #3d4652;
                      "
                    >
                      Aging Effect & Future Baby Maker
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="t391__secondcol t-col t-col_5">
              <div
                class="t-cover__wrapper t-valign_bottom"
                style="height: 100vh"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec101365260"
      class="r t-rec t-rec_pt_30 t-rec_pb_30 t-screenmax-320px"
      style="
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: rgb(174, 159, 219);
      "
      data-animationappear="off"
      data-record-type="524"
      data-screen-max="320px"
      data-bg-color="#858da4"
    >
      <!-- t524 -->
      <div class="t524">
        <div class="t-section__container t-container">
          <div class="t-col t-col_12">
            <div class="t-section__topwrapper t-align_center">
              <div class="t-section__title t-title t-title_xs" field="btitle">
                <div
                  style="font-size: 26px; font-family: 'Ubuntu'; color: #ede4e4"
                >
                  AI face app that turns your Selfie into exciting effects.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="t524__container t-container">
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto" style="width: 150px">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477995899606"
                  id="landing-stress"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477995899606"
                >
                  <div
                    style="
                      font-size: 16px;
                      font-family: 'Ubuntu';
                      color: #ede4e4;
                    "
                  >
                    3D Cartoon
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto" style="width: 150px">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996238469"
                  id="landing_forgetfullne"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996238469"
                >
                  <div
                    style="
                      font-size: 16px;
                      font-family: 'Ubuntu';
                      color: #ede4e4;
                    "
                  >
                    Art Portrait
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto" style="width: 150px">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996247186"
                  id="Lost-productivity"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996247186"
                >
                  <div
                    style="
                      font-size: 16px;
                      font-family: 'Ubuntu';
                      color: #ede4e4;
                    "
                  >
                    Baby Prediction
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto" style="width: 150px">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="Absent-mindedness"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div
                    style="
                      font-size: 16px;
                      font-family: 'Ubuntu';
                      color: #ede4e4;
                    "
                  >
                    Cosplay
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="GenderSwap"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Gender Swap
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="TimeMachine"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Face Aging
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="YoungEffect"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Young Effect
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec98913286"
      class="
        r
        t-rec t-rec_pt_45 t-rec_pb_45
        t-screenmin-320px t-screenmax-480px
      "
      style="
        padding-top: 45px;
        padding-bottom: 45px;
        background-color: rgb(174, 159, 219);
      "
      data-animationappear="off"
      data-record-type="524"
      data-screen-min="320px"
      data-screen-max="480px"
      data-bg-color="#858da4"
    >
      <!-- t524 -->
      <div class="t524">
        <div class="t-section__container t-container">
          <div class="t-col t-col_12">
            <div class="t-section__topwrapper t-align_center">
              <div class="t-section__title t-title t-title_xs" field="btitle">
                <div style="font-family: Ubuntu; color: rgb(237, 228, 228)">
                  AI face app that turns your Selfie into exciting effects.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="t524__container t-container">
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto" style="width: 150px">
                <div
                  class="
                    t524__bgimg t524__img_circle
                    t-margin_auto
                    t-bgimg
                    loaded
                  "
                  bgimgfield="li_img__1477995899606"
                  id="landing-stress"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477995899606"
                >
                  <div
                    style="
                      font-size: 18px;
                      font-family: 'Ubuntu';
                      color: #ede4e4;
                    "
                  >
                    3D Cartoon
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto" style="width: 150px">
                <div
                  class="
                    t524__bgimg t524__img_circle
                    t-margin_auto
                    t-bgimg
                    loaded
                  "
                  bgimgfield="li_img__1477996238469"
                  id="landing_forgetfullne"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996238469"
                >
                  <div
                    style="
                      font-size: 18px;
                      line-height: 24px;
                      font-family: 'Ubuntu';
                      color: #ede4e4;
                    "
                  >
                    Art Portrait
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto" style="width: 150px">
                <div
                  class="
                    t524__bgimg t524__img_circle
                    t-margin_auto
                    t-bgimg
                    loaded
                  "
                  bgimgfield="li_img__1477996247186"
                  id="Lost-productivity"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996247186"
                >
                  <div
                    style="
                      font-size: 18px;
                      font-family: 'Ubuntu';
                      color: #ede4e4;
                    "
                  >
                    Baby Prediction
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto" style="width: 150px">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="Absent-mindedness"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div
                    style="
                      font-size: 18px;
                      font-family: 'Ubuntu';
                      color: #ede4e4;
                    "
                  >
                    Cosplay
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="GenderSwap"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Gender Swap
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="TimeMachine"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Face Aging
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="YoungEffect"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Young Effect
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec101358525"
      class="r t-rec t-rec_pt_30 t-rec_pb_90 t-screenmin-980px"
      style="
        padding-top: 30px;
        padding-bottom: 45px;
        background-color: rgb(174, 159, 219);
      "
      data-record-type="524"
      data-screen-min="980px"
    >
      <!-- t524 -->
      <div class="t524">
        <div class="t-section__container t-container">
          <div class="t-col t-col_12">
            <div class="t-section__topwrapper t-align_center">
              <div class="t-section__title t-title t-title_xs" field="btitle">
                <div style="font-family: Ubuntu; color: rgb(237, 228, 228)">
                  AI face app that turns your Selfie into exciting effects.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="t524__container t-container">
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477995899606"
                  id="landing-stress"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477995899606"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    3D Cartoon
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996238469"
                  id="landing_forgetfullne"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996238469"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Art Portrait
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996247186"
                  id="Lost-productivity"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996247186"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Baby Prediction
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="Absent-mindedness"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Cosplay
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="GenderSwap"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Gender Swap
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="TimeMachine"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Face Aging
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              t524__col
              t-col t-col_3
              t-align_center
              t524__col-mobstyle
              overcome-item
            "
          >
            <div class="t524__itemwrapper t524__itemwrapper_4">
              <div class="t524__imgwrapper t-margin_auto">
                <div
                  class="t524__bgimg t524__img_circle t-margin_auto t-bgimg"
                  bgimgfield="li_img__1477996467033"
                  id="YoungEffect"
                ></div>
              </div>
              <div class="t524__wrappercenter">
                <div
                  class="t524__persname t-name t-name_lg"
                  style=""
                  field="li_persname__1477996467033"
                >
                  <div style="font-family: 'Ubuntu'; color: #ede4e4">
                    Young Effect
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec98243881"
      class="r t-rec t-rec_pt_45 t-rec_pb_45 t-screenmax-320px"
      style="padding-top: 45px; padding-bottom: 45px; background-color: #ede4e4"
      data-record-type="509"
      data-screen-max="320px"
      data-bg-color="#ede4e4"
    >
      <!-- t509 -->
      <div class="t509">
        <div class="t-section__container t-container">
          <div class="t-col t-col_12">
            <div class="t-section__topwrapper t-align_center">
              <div class="t-section__title t-title t-title_xs" field="btitle">
                <div
                  style="font-size: 26px; font-family: 'Ubuntu'; color: #3d4652"
                >
                  Discover more fun
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="t-container">
          <div class="t509__colwrapper t-col t-col_12">
            <div class="t-item">
              <div class="t509__col t-col t-col_6 t509__mobileimg">
                <div
                  class="t509__blockimg t-bgimg"
                  bgimgfield="li_img__1476897493817"
                  id="Discover-Impulse"
                  style="padding-bottom: 62.5%"
                ></div>
              </div>
              <div
                class="t509__col t-col t-col_6 t509__leftcol t509__desktopimg"
                style=""
              >
                <div class="t509__imgwrapper" style="">
                  <div
                    class="t509__blockimg t-bgimg"
                    bgimgfield="li_img__1476897493817"
                    id="Discover-Impulse"
                  ></div>
                </div>
              </div>
              <div class="t509__col t-col t-col_6 t509__rightcol">
                <div
                  class="t509__textwrapper t-align_center t509__marginauto"
                  style="max-width: 600px"
                >
                  <div class="t509__content t-valign_middle">
                    <div class="t509__box">
                      <div
                        class="t509__title t-heading t-heading_xs t-margin_auto"
                        field="li_title__1476897493817"
                        style=""
                      >
                        <div
                          style="
                            font-size: 22px;
                            line-height: 30px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Art Portrait
                        </div>
                      </div>
                      <div
                        class="t509__descr t-descr t-descr_sm t-margin_auto"
                        field="li_descr__1476897493817"
                        style=""
                      >
                        <div
                          style="
                            font-size: 14px;
                            line-height: 26px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Convert your photos to realistic portraits by using
                          latest artificial intelligence and deep learning
                          technologies.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="t509__separator t-clear"
              style="margin-bottom: 100px"
            ></div>
            <div class="t-item">
              <div class="t509__col t-col t-col_6 t509__mobileimg">
                <div
                  class="t509__blockimg t-bgimg"
                  bgimgfield="li_img__1476897695209"
                  id="Target-core-cognitive-areas"
                ></div>
              </div>
              <div class="t509__col t-col t-col_6 t509__leftcol">
                <div
                  class="t509__textwrapper t-align_center t509__marginauto"
                  style="max-width: 600px"
                >
                  <div class="t509__content t-valign_middle">
                    <div class="t509__box">
                      <div
                        class="t509__title t-heading t-heading_xs t-margin_auto"
                        field="li_title__1476897695209"
                        style=""
                      >
                        <div
                          style="
                            font-size: 22px;
                            line-height: 30px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Meditation
                        </div>
                      </div>
                      <div
                        class="t509__descr t-descr t-descr_sm t-margin_auto"
                        field="li_descr__1476897695209"
                        style=""
                      >
                        <div
                          style="
                            font-size: 14px;
                            line-height: 22px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Meditation helps calm the mind, reduce anxiety, manage
                          stress, sleep deeply and improve happiness.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="t509__col t-col t-col_6 t509__rightcol t509__desktopimg"
                style=""
              >
                <div class="t509__imgwrapper" style="">
                  <div
                    class="t509__blockimg t-bgimg"
                    bgimgfield="li_img__1476897695209"
                    id="Target-core-cognitive-areas"
                  ></div>
                </div>
              </div>
            </div>

            <div
              class="t509__separator t-clear"
              style="margin-bottom: 100px"
            ></div>
            <div class="t-item">
              <div class="t509__col t-col t-col_6 t509__mobileimg">
                <div
                  class="t509__blockimg t-bgimg"
                  bgimgfield="li_img__1555075073869"
                  id="Boost-your-brain-power"
                ></div>
              </div>
              <div
                class="t509__col t-col t-col_6 t509__leftcol t509__desktopimg"
                style=""
              >
                <div class="t509__imgwrapper" style="">
                  <div
                    class="t509__blockimg t-bgimg"
                    bgimgfield="li_img__1555075073869"
                    id="Boost-your-brain-power"
                  ></div>
                </div>
              </div>
              <div class="t509__col t-col t-col_6 t509__rightcol">
                <div
                  class="t509__textwrapper t-align_center t509__marginauto"
                  style="max-width: 600px"
                >
                  <div class="t509__content t-valign_middle">
                    <div class="t509__box">
                      <div
                        class="t509__title t-heading t-heading_xs t-margin_auto"
                        field="li_title__1555075073869"
                        style=""
                      >
                        <div
                          style="
                            font-size: 22px;
                            line-height: 30px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Personality Tests <br />
                        </div>
                      </div>
                      <div
                        class="t509__descr t-descr t-descr_sm t-margin_auto"
                        field="li_descr__1555075073869"
                        style=""
                      >
                        <div
                          style="
                            font-size: 14px;
                            line-height: 22px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Personality tests help you know more about yourself.
                          They give you personalized guidances to live a life in
                          harmony with who you truly are.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec101365412"
      class="
        r
        t-rec t-rec_pt_45 t-rec_pb_45
        t-screenmin-320px t-screenmax-480px
      "
      style="padding-top: 45px; padding-bottom: 45px; background-color: #ede4e4"
      data-record-type="509"
      data-screen-min="320px"
      data-screen-max="480px"
      data-bg-color="#ede4e4"
    >
      <!-- t509 -->
      <div class="t509">
        <div class="t-section__container t-container">
          <div class="t-col t-col_12">
            <div class="t-section__topwrapper t-align_center">
              <div class="t-section__title t-title t-title_xs" field="btitle">
                <div style="font-family: Ubuntu; color: rgb(61, 70, 82)">
                  Discover more fun
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="t-container">
          <div class="t509__colwrapper t-col t-col_12">
            <div class="t-item">
              <div class="t509__col t-col t-col_6 t509__mobileimg">
                <div
                  class="t509__blockimg t-bgimg"
                  bgimgfield="li_img__1476897493817"
                  id="Discover-Impulse"
                ></div>
              </div>
              <div
                class="t509__col t-col t-col_6 t509__leftcol t509__desktopimg"
                style=""
              >
                <div class="t509__imgwrapper" style="">
                  <div
                    class="t509__blockimg t-bgimg"
                    bgimgfield="li_img__1476897493817"
                    id="Discover-Impulse"
                  ></div>
                </div>
              </div>
              <div class="t509__col t-col t-col_6 t509__rightcol">
                <div
                  class="t509__textwrapper t-align_center t509__marginauto"
                  style="max-width: 600px"
                >
                  <div class="t509__content t-valign_middle">
                    <div class="t509__box">
                      <div
                        class="t509__title t-heading t-heading_xs t-margin_auto"
                        field="li_title__1476897493817"
                        style=""
                      >
                        <div
                          style="
                            font-size: 24px;
                            line-height: 30px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Art Portrait
                        </div>
                      </div>
                      <div
                        class="t509__descr t-descr t-descr_sm t-margin_auto"
                        field="li_descr__1476897493817"
                        style=""
                      >
                        <div
                          style="
                            font-size: 16px;
                            line-height: 26px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Convert your photos to realistic portraits by using
                          latest artificial intelligence and deep learning
                          technologies.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="t509__separator t-clear"
              style="margin-bottom: 100px"
            ></div>
            <div class="t-item">
              <div class="t509__col t-col t-col_6 t509__mobileimg">
                <div
                  class="t509__blockimg t-bgimg"
                  bgimgfield="li_img__1476897695209"
                  id="Target-core-cognitive-areas"
                ></div>
              </div>
              <div class="t509__col t-col t-col_6 t509__leftcol">
                <div
                  class="t509__textwrapper t-align_center t509__marginauto"
                  style="max-width: 600px"
                >
                  <div class="t509__content t-valign_middle">
                    <div class="t509__box">
                      <div
                        class="t509__title t-heading t-heading_xs t-margin_auto"
                        field="li_title__1476897695209"
                        style=""
                      >
                        <div
                          style="
                            font-size: 24px;
                            line-height: 30px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Meditation
                        </div>
                      </div>
                      <div
                        class="t509__descr t-descr t-descr_sm t-margin_auto"
                        field="li_descr__1476897695209"
                        style=""
                      >
                        <div
                          style="
                            font-size: 16px;
                            line-height: 22px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Meditation helps calm the mind, reduce anxiety, manage
                          stress, sleep deeply and improve happiness.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="t509__col t-col t-col_6 t509__rightcol t509__desktopimg"
                style=""
              >
                <div class="t509__imgwrapper" style="">
                  <div
                    class="t509__blockimg t-bgimg"
                    bgimgfield="li_img__1476897695209"
                    id="Target-core-cognitive-areas"
                  ></div>
                </div>
              </div>
            </div>
            <div
              class="t509__separator t-clear"
              style="margin-bottom: 100px"
            ></div>
            <div class="t-item">
              <div class="t509__col t-col t-col_6 t509__mobileimg">
                <div
                  class="t509__blockimg t-bgimg"
                  bgimgfield="li_img__1555075073869"
                  id="Boost-your-brain-power"
                ></div>
              </div>
              <div
                class="t509__col t-col t-col_6 t509__leftcol t509__desktopimg"
                style=""
              >
                <div class="t509__imgwrapper" style="">
                  <div
                    class="t509__blockimg t-bgimg"
                    bgimgfield="li_img__1555075073869"
                    id="Boost-your-brain-power"
                  ></div>
                </div>
              </div>
              <div class="t509__col t-col t-col_6 t509__rightcol">
                <div
                  class="t509__textwrapper t-align_center t509__marginauto"
                  style="max-width: 600px"
                >
                  <div class="t509__content t-valign_middle">
                    <div class="t509__box">
                      <div
                        class="t509__title t-heading t-heading_xs t-margin_auto"
                        field="li_title__1555075073869"
                        style=""
                      >
                        <div
                          style="
                            font-size: 24px;
                            line-height: 30px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Personality Tests <br />
                        </div>
                      </div>
                      <div
                        class="t509__descr t-descr t-descr_sm t-margin_auto"
                        field="li_descr__1555075073869"
                        style=""
                      >
                        <div
                          style="
                            font-size: 16px;
                            line-height: 22px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Personality tests help you know more about yourself.
                          They give you personalized guidances to live a life in
                          harmony with who you truly are.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec101361377"
      class="r t-rec t-rec_pt_90 t-rec_pb_90 t-screenmin-980px"
      style="padding-top: 90px; padding-bottom: 45px; background-color: #ede4e4"
      data-record-type="509"
      data-screen-min="980px"
      data-bg-color="#ede4e4"
    >
      <!-- t509 -->
      <div class="t509">
        <div class="t-section__container t-container">
          <div class="t-col t-col_12">
            <div class="t-section__topwrapper t-align_center">
              <div class="t-section__title t-title t-title_xs" field="btitle">
                <div style="font-family: Ubuntu; color: rgb(61, 70, 82)">
                  Discover more fun
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="t-container">
          <div class="t509__colwrapper t-col t-col_12">
            <div class="t-item">
              <div class="t509__col t-col t-col_6 t509__mobileimg">
                <div
                  class="t509__blockimg t-bgimg"
                  bgimgfield="li_img__1476897493817"
                  id="Discover-Impulse"
                ></div>
              </div>
              <div
                class="t509__col t-col t-col_6 t509__leftcol t509__desktopimg"
                style=""
              >
                <div class="t509__imgwrapper" style="">
                  <div
                    class="t509__blockimg t-bgimg"
                    bgimgfield="li_img__1476897493817"
                    id="Discover-Impulse"
                  ></div>
                </div>
              </div>
              <div class="t509__col t-col t-col_6 t509__rightcol">
                <div
                  class="t509__textwrapper t-align_left"
                  style="max-width: 600px"
                >
                  <div class="t509__content t-valign_middle">
                    <div class="t509__box">
                      <div
                        class="t509__title t-heading t-heading_xs t-margin_auto"
                        field="li_title__1476897493817"
                        style=""
                      >
                        <div
                          style="font-family: Ubuntu; color: rgb(61, 70, 82)"
                        >
                          Art Portrait
                        </div>
                      </div>
                      <div
                        class="t509__descr t-descr t-descr_sm t-margin_auto"
                        field="li_descr__1476897493817"
                        style=""
                      >
                        <div
                          style="
                            font-size: 20px;
                            line-height: 30px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Convert your photos to realistic portraits by using
                          latest artificial intelligence and deep learning
                          technologies.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="t509__separator t-clear"
              style="margin-bottom: 150px"
            ></div>
            <div class="t-item">
              <div class="t509__col t-col t-col_6 t509__mobileimg">
                <div
                  class="t509__blockimg t-bgimg"
                  bgimgfield="li_img__1476897695209"
                  id="Target-core-cognitive-areas"
                ></div>
              </div>
              <div class="t509__col t-col t-col_6 t509__leftcol">
                <div
                  class="t509__textwrapper t-align_left"
                  style="max-width: 600px"
                >
                  <div class="t509__content t-valign_middle">
                    <div class="t509__box">
                      <div
                        class="t509__title t-heading t-heading_xs t-margin_auto"
                        field="li_title__1476897695209"
                        style=""
                      >
                        <div
                          style="font-family: Ubuntu; color: rgb(61, 70, 82)"
                        >
                          Meditation
                        </div>
                      </div>
                      <div
                        class="t509__descr t-descr t-descr_sm t-margin_auto"
                        field="li_descr__1476897695209"
                        style=""
                      >
                        <div
                          style="
                            font-size: 20px;
                            line-height: 30px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Meditation helps calm the mind, reduce anxiety, manage
                          stress, sleep deeply and improve happiness.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="t509__col t-col t-col_6 t509__rightcol t509__desktopimg"
                style=""
              >
                <div class="t509__imgwrapper" style="">
                  <div
                    class="t509__blockimg t-bgimg"
                    bgimgfield="li_img__1476897695209"
                    id="Target-core-cognitive-areas"
                  ></div>
                </div>
              </div>
            </div>
            <div
              class="t509__separator t-clear"
              style="margin-bottom: 150px"
            ></div>
            <div class="t-item">
              <div class="t509__col t-col t-col_6 t509__mobileimg">
                <div
                  class="t509__blockimg t-bgimg"
                  bgimgfield="li_img__1555075073869"
                  id="Boost-your-brain-power"
                ></div>
              </div>
              <div
                class="t509__col t-col t-col_6 t509__leftcol t509__desktopimg"
                style=""
              >
                <div class="t509__imgwrapper" style="">
                  <div
                    class="t509__blockimg t-bgimg"
                    bgimgfield="li_img__1555075073869"
                    id="Boost-your-brain-power"
                  ></div>
                </div>
              </div>
              <div class="t509__col t-col t-col_6 t509__rightcol">
                <div
                  class="t509__textwrapper t-align_left"
                  style="max-width: 600px"
                >
                  <div class="t509__content t-valign_middle">
                    <div class="t509__box">
                      <div
                        class="t509__title t-heading t-heading_xs t-margin_auto"
                        field="li_title__1555075073869"
                        style=""
                      >
                        <div
                          style="font-family: Ubuntu; color: rgb(61, 70, 82)"
                        >
                          Personality Tests <br />
                        </div>
                      </div>
                      <div
                        class="t509__descr t-descr t-descr_sm t-margin_auto"
                        field="li_descr__1555075073869"
                        style=""
                      >
                        <div
                          style="
                            font-size: 20px;
                            line-height: 30px;
                            font-family: 'Ubuntu';
                            color: #3d4652;
                          "
                        >
                          Personality tests help you know more about yourself.
                          They give you personalized guidances to live a life in
                          harmony with who you truly are.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec101363673"
      class="r t-rec t-rec_pt_45 t-rec_pb_45 t-screenmax-320px"
      style="padding-top: 45px; padding-bottom: 45px; background-color: #858da4"
      data-record-type="580"
      data-screen-max="320px"
      data-bg-color="#858da4"
    >
      <!-- T580 -->
      <div class="t580">
        <div class="t-container">
          <div class="t-col t-col_10 t-prefix_1 t-align_center">
            <div
              class="t580__title t-title t-title_sm t-margin_auto"
              style="color: #000000"
              field="title"
            >
              <div style="font-family: Ubuntu; color: rgb(237, 228, 228)">
                Love Life, Get Fun
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec101365774"
      class="
        r
        t-rec t-rec_pt_45 t-rec_pb_45
        t-screenmin-320px t-screenmax-480px
      "
      style="padding-top: 45px; padding-bottom: 45px; background-color: #858da4"
      data-record-type="580"
      data-screen-min="320px"
      data-screen-max="480px"
      data-bg-color="#858da4"
    >
      <!-- T580 -->
      <div class="t580">
        <div class="t-container">
          <div class="t-col t-col_10 t-prefix_1 t-align_center">
            <div
              class="t580__title t-title t-title_sm t-margin_auto"
              style="color: #000000"
              field="title"
            >
              <div style="font-family: Ubuntu; color: rgb(237, 228, 228)">
                Love Life, Get Fun
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec100869547"
      class="r t-rec t-rec_pt_90 t-rec_pb_90 t-screenmin-980px"
      style="padding-top: 90px; padding-bottom: 45px; background-color: #858da4"
      data-record-type="580"
      data-screen-min="980px"
      data-bg-color="#858da4"
      data-animationappear="off"
    >
      <!-- T580 -->
      <div class="t580">
        <div class="t-container">
          <div class="t-col t-col_10 t-prefix_1 t-align_center">
            <div
              class="t580__title t-title t-title_sm t-margin_auto"
              style="color: #000000"
              field="title"
            >
              <div
                style="font-size: 52px; font-family: 'Ubuntu'; color: #ede4e4"
                data-customstyle="yes"
              >
                Love Life, Get Fun
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      id="rec98243957"
      class="r t-rec t-rec_pt_0"
      style="padding-top: 0px; background-color: #858da4"
      data-animationappear="off"
      data-record-type="345"
      data-bg-color="#858da4"
    >
      <!-- T345 -->
    </div>
    <Footer />
  </div>
</template>

//
<script>
import Footer from "@/components/Footer/Footer";
// import Header from "@/components/Header";
// import Banner from "@/components/Banner";
// import About from "@/components/About";
// import Contact from "@/components/Contact";
export default {
  name: "Home",
  components: {
    Footer
  }
};
</script>

<style scoped></style>
