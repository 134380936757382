<template>
  <section style="display: flex; flex-direction: column">
    <h2 class="page-title">PRIVACY POLICY</h2>
    <PrivacyContent class="col-lg-10"></PrivacyContent>
    <!-- <h2 class="page-title terms-title">TERMS OF SERVICE</h2>
    <TermsContent class="col-lg-10"></TermsContent> -->
    <Footer />
  </section>
</template>
<script>
import PrivacyContent from "@/components/PrivacyTerms/PrivacyContent";
// import TermsContent from "@/components/PrivacyTerms/TermsContent";
import Footer from "@/components/Footer/Footer";
export default {
  components: {
    PrivacyContent,
    // TermsContent,
    Footer
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.name === "terms") {
        document.querySelector(".terms-title").scrollIntoView(true);
      }
    });
  }
};
</script>

<style scoped>
.page-title {
  font-size: 32px;
  text-align: center;
  padding: 64px 0 32px;
}
</style>
