/**
 * 记录发邮件的次数，一个小时内，只允许发两次
 */
const SEND_EMAIL_TIMES = "send_email_times";

class CachSendEmail {
  constructor() {
    this.sendEmailParams = {
      startTime: 0, //时间
      sendNumber: 0, // 发送次数
    };
    this.interval = 60 * 60 * 1000; // 一个小时;
    this.init();
  }
  init() {
    const cachData = window.localStorage.getItem(SEND_EMAIL_TIMES);
    if (cachData) {
      this.sendEmailParams = JSON.parse(cachData);
    }
  }
  sendEmailEnable() {
    // 检查当前是否允许发送email
    const nowTime = new Date().getTime();
    const { sendNumber, startTime } = this.sendEmailParams;
    return sendNumber < 2 || nowTime - startTime > this.interval;
  }

  storageSendParams() {
    const nowTime = new Date().getTime();
    const { startTime } = this.sendEmailParams;
    let newStartTime = 0;
    let newSendNumber = 1;
    if (startTime === 0 || nowTime - startTime > this.interval) {
      // 第一次发送，或者间隔超过了1小时
      newStartTime = nowTime;
    } else {
      newStartTime = startTime;
      newSendNumber = 2;
    }
    this.sendEmailParams = {
      startTime: newStartTime,
      sendNumber: newSendNumber,
    };
    window.localStorage.setItem(
      SEND_EMAIL_TIMES,
      JSON.stringify(this.sendEmailParams)
    );
  }
}

export default new CachSendEmail();
