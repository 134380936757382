<template>
  <section class="contact-filed">
    <div class="container">
      <div class="contact">
        <form method="post" id="contactus" name="contactus">
          <div class="contact-grids">
            <!-- <DropSelect
              :error="selectError"
              v-model="selectValue"
              :dropDataList="dropList"
            ></DropSelect> -->
            <InputFiled
              :error="nameError"
              v-model="name"
              placeholder="Name"
            ></InputFiled>
            <InputFiled
              :error="emailError"
              v-model="email"
              inputType="email"
              :errorMessage="emailErrorMessage"
              placeholder="Email"
            ></InputFiled>
            <div class="clearfix"></div>
          </div>
          <div class="contact-grids1">
            <InputFiled
              v-model="message"
              :error="messageError"
              inputType="textarea"
              placeholder="Type Your Message"
              className="col-md-11 contactgrid1"
            ></InputFiled>
            <div class="clearfix"></div>
          </div>
        </form>
        <div class="clearfix"></div>
      </div>
    </div>

    <div class="footer-main">
      <div class="footer-button">
        <a href="javascript:;" @click="sendMessage">Send Message</a>
      </div>
      <div class="clearfix"></div>
    </div>
    <Toast v-model="toastValue" :error="errorToast"></Toast>
  </section>
</template>

<script>
import InputFiled from "@/components/InputFiled/InputFiled";
// import DropSelect from "@/components/DropSelect/DropSelect";
import { sendEmail } from "@/api/question/index.js";
import DefaultParams from "@/utils/defalut_params.js";
import CachSendEmail from "@/utils/cach_send_email.js";
import Toast from "@/components/Toast/Toast";

export default {
  components: {
    InputFiled,
    // DropSelect,
    Toast
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      // selectValue: {},
      toastValue: "",
      nameError: false,
      selectError: false,
      emailError: false,
      messageError: false,
      errorToast: false,
      emailErrorMessage: "",
      dropList: [
        {
          value: "suggest more quotes"
        },
        { value: "report a bug" },
        { value: "app feedback" },
        { value: "others" }
      ]
    };
  },
  methods: {
    validateForm() {
      this.selectError =
        this.nameError =
        this.messageError =
        this.emailError =
          false;
      // if (!this.selectValue || JSON.stringify(this.selectValue) === "{}") {
      //   this.selectError = true;
      //   return false;
      // }

      if (!this.name) {
        this.nameError = true;
        return false;
      }
      let re = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
      if (!this.email || !re.test(this.email)) {
        this.emailErrorMessage = "Please enter a valid email";
        this.emailError = true;
        return;
      }

      if (!this.message) {
        this.messageError = true;
        return false;
      }
      return true;
    },
    sendMessage() {
      if (!this.validateForm()) {
        return;
      }

      const canSendEmail = CachSendEmail.sendEmailEnable();
      if (!canSendEmail) {
        // 一个小时内超过两次，禁止发送
        this.errorToast = true;
        this.toastValue = "You operation are too frequent. Take a break!";
        return;
      }
      this.showLoading = true;
      const nativeParams = { ...DefaultParams.getAppInfo() };
      const access_token = nativeParams.access_token;
      delete nativeParams["access_token"];
      const body = {
        ...nativeParams,
        feedback: {
          // question_type: this.selectValue.index,
          // question: this.selectValue.value,
          name: this.name,
          email: this.email,
          suggestion: this.message
        }
      };
      sendEmail(body, access_token)
        .then(() => {
          CachSendEmail.storageSendParams();
          this.errorToast = false;
          this.toastValue = "Thanks for submitting.";
          this.clearFrom();
        })
        .catch(err => {
          console.log("clickSendEmail err", err);
          this.errorToast = true;
          this.toastValue = "Something went wrong.";
        });
    },
    clearFrom() {
      this.name = this.message = this.email = "";
      this.selectValue = {};
    }
  }
};
</script>
