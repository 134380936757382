import axios from "axios";
import { getEncryptData } from "../utils/encrypt";
import DefaultParams from "@/utils/defalut_params.js";

const http = axios.create({
  timeout: 15000,
  method: "POST",
  headers: { "Content-Type": "application/json" },
});

const getRequestData = (config) => {
  const dataBody = {
    method: config.method,
    path: config.url,
    body: config.data,
  };
  const paramsData = getEncryptData(dataBody);
  const baseURL = DefaultParams.getBaseUrl();
  return { baseURL, data: paramsData };
};

export const post = async (url, body, access_token) => {
  const data = {
    url,
    method: "POST",
    data: body,
  };
  if (access_token) {
    data["headers"] = {
      Authorization: access_token,
    };
  }
  const request_data = getRequestData(data);
  return await http.request(request_data);
};
