import { post } from "../base";

export const sendEmail = async (body, access_token) => {
  try {
    const result = await post(
      "/umpXaM/YK74bK/NqIC89/Z3YtZM",
      body,
      access_token
    );
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};
