<template>
  <div :class="className">
    <textarea
      v-if="inputType === 'textarea'"
      id="message"
      v-model="inputValue"
      name="message"
      :placeholder="placeholder"
      @blur="inputBlur"
    ></textarea>
    <input
      v-else
      :type="inputType"
      class="text"
      id="name"
      name="name"
      v-model="inputValue"
      :placeholder="placeholder"
      @blur="inputBlur"
    />
    <label
      for="name"
      generated="true"
      class="error"
      v-if="error"
      style="display: inline-block"
      >{{ errorMessageShow }}</label
    >
  </div>
</template>

<script>
export default {
  props: {
    inputType: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: "col-md-5 contactgrid"
    },
    value: {
      default: "",
      type: String
    },
    errorMessage: {
      default: "",
      type: String
    },
    error: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      inputValue: "",
      activeClass: "",
      defaultErrorMessage: "This field is required."
    };
  },
  computed: {
    errorMessageShow() {
      return this.errorMessage || this.defaultErrorMessage;
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.inputValue = val;
      },
      immediate: true
    }
  },
  methods: {
    inputBlur() {
      this.$emit("input", this.inputValue);
    }
  }
};
</script>
