<template>
  <section style="display: flex; flex-direction: column">
    <h2 class="page-title">TERMS OF SERVICE</h2>
    <TermsContent class="col-lg-10"></TermsContent>
    <Footer />
  </section>
</template>
<script>
import Footer from "@/components/Footer/Footer";

import TermsContent from "@/components/PrivacyTerms/TermsContent";

export default {
  components: {
    TermsContent,
    Footer
  }
};
</script>
<style scoped>
.page-title {
  font-size: 32px;
  text-align: center;
  padding: 64px 0 32px;
}
</style>
