<template>
  <div
    v-if="value"
    class="toast-alert alert"
    :class="error ? 'alert-danger' : 'alert-success'"
    role="alert"
  >
    {{ value }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: ""
    },
    error: {
      default: false
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          setTimeout(() => {
            this.$emit("input", "");
          }, 2000);
        }
      },
      immediate: true
    }
  }
};
</script>

<style>
.toast-alert {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.alert {
  padding: 0.75rem;
  text-align: center;
}
</style>
