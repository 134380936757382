<template>
  <section class="footer">
    <router-link to="/">Home</router-link>
    <router-link to="/privacy">Privacy Policy</router-link>
    <router-link to="/terms">Terms of Service</router-link>
    <router-link to="/support">Support</router-link>
  </section>
</template>

<script>
export default {
  data() {
    return {
      yearTime: new Date().getFullYear()
    };
  }
};
</script>

<style>
.privacy {
  padding: 1rem 0 !important;
}
a {
  color: #fff !important;
  margin: 0 12px !important;
}
.footer {
  background: rgb(133, 141, 164) !important;
  display: flex;
  justify-content: center;
}
@media (max-width: 576px) {
  a {
    margin: 0 6px !important;
    font-size: 14px;
  }
}
</style>
